<template>
  <Component :is="currentTabComponent" />
</template>

<script>
import leave from '@/views/ContactUs/Leave';
import join from '@/views/ContactUs/Join';

export default {
  name: 'LeaveIndex',
  components: {
    leave,
    join,
  },
  data() {
    return {
      currentTabComponent: leave,
      classList: [
        {
          // 留言
          component: leave,
        },
        {
          // 加入我们
          component: join,
        },
      ],
    };
  },
  watch: {
    '$route.query': {
      immediate: true,
      deep: true,
      handler(newV) {
        this.changeTab(newV.index ?? 0);
      },
    },
  },
  methods: {
    changeTab(index) {
      this.currentTabComponent = this.classList[index].component;
    },
  },
};
</script>

<style></style>
