<template>
  <!-- 联系我们 -->
  <div class="main">
    <div class="map">
      <baidu-map
        :center="center"
        :zoom="zoom"
        :scroll-wheel-zoom="true"
        style="height: 100%;"
      >
        <bm-geolocation
          anchor="BMAP_ANCHOR_BOTTOM_RIGHT"
          :show-address-bar="true"
          :auto-location="true"
        />
        <bm-marker
          :position="{ lng: 113.13816, lat: 22.56931 }"
          animation="BMAP_ANIMATION_BOUNCE"
        >
          <bm-label
            content="广东安雅园新型材料科技有限公司"
            :offset="{ width: -55, height: 30 }"
          />
        </bm-marker>
      </baidu-map>
    </div>

    <div class="content">
      <!-- 标题 -->
      <div class="Box">
        <span class="Title">CONTACT US</span>
        <span class="Chtitle">联系我们</span>
      </div>
      <!-- 简介 -->
      <div class="title">
        <span>如果您正在考虑选择一个轻钢材料供应商？那么，最好的厂家就在这里。
        </span>
        <span>在安雅园建筑新型材料，我们致力于成为轻钢行业最值得信赖的综合服务商，始终秉承以技术为核心，以品质为保障，以服务为宗旨，为客户提供出最优的解决方案，真正的做到服务客户，尊重客户。</span>
      </div>
      <!-- 联系方式 -->
      <div class="contact-info">
        <template v-for="(item,index) in contactList">
          <div
            :key="index"
            class="contactList"      
          >
            <div
              class="item-icon"
              :style="{
                '--src': `url(${ item.img })`, 
                '--hover-src': `url(${ item.hoverImg })`
              }"
            />
            <div class="item-title">
              {{ item.title }}
            </div>
            <div class="item-detail">
              {{ item.detail }}
            </div>
          </div>
        </template>
      </div>
    </div>

    <div id="contact0" class="call">
      <div class="title">
        <h3>
          在线留言
        </h3>
      </div>
      <div class="card">
        <span>温馨提示：有任何问题或需求，欢迎您随时咨询我们，也可以填写下面留言板，我们会即时为您服务！</span>

        <ElForm
          ref="form"
          :model="form"
          :rules="rules"
          label-width="auto"
          hide-required-asterisk
        >
          <ElFormItem label="姓名" prop="name">
            <ElInput v-model="form.name" />
          </ElFormItem>
          <ElFormItem label="电话" prop="phone">
            <ElInput v-model="form.phone" />
          </ElFormItem>
          <ElFormItem label="选择资讯范围" prop="classid">
            <ElSelect
              v-model="form.classid"
              placeholder="请选择资讯的内容"
              style="width: 100%;"
            >
              <ElOption
                v-for="item in classList"
                :key="item.value"
                :label="item.name"
                :value="item.id"
              />
            </ElSelect>
          </ElFormItem>
          <ElFormItem label="详细信息" prop="content">
            <ElInput v-model="form.content" type="textarea" :rows="5" />
          </ElFormItem>
          <ElFormItem>
            <ElButton type="success" @click="submitForm">
              提交
            </ElButton>
            <ElButton type="primary" @click="resetForm">
              重置
            </ElButton>
          </ElFormItem>
        </ElForm>
      </div>
    </div>
  </div>
</template>

<script>
import { contactInfo, leave, leaveClass } from '@/api/about';

export default {
  name: 'LeaveIndex',
  data() {
    return {
      id: '',
      center: '江门市江海区火炬大厦', // 经纬度
      zoom: 18, // 地图展示级别
      form: {
        name: '',
        phone: '',
        content: '',
        leaveClassId: '',
        classid: '',
      },
      companyEmail: '',
      phone: '',
      site: '',
      telephone: '',
      classList: [],
      rules: {
        name: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
          { min: 2, max: 5, message: '请按要求输入姓名', trigger: 'blur' },
        ],
        phone: [
          { required: true, message: '请输入手机号码', trigger: 'blur' },
          {
            min: 11,
            max: 11,
            message: '请输入正确的手机号码',
            trigger: 'blur',
          },
        ],
        classid: [
          { required: true, message: '请选择资讯内容', trigger: 'change' },
        ],
        content: [
          { required: true, message: '请填写详细信息', trigger: 'blur' },
        ],
      },
      contactList: [
        {
          hover: false,
          img: 'https://img.kcgeis.com/0026644965.png',
          hoverImg: 'https://img.kcgeis.com/6192675305.png',
          title: '公司名称',
          detail: '',
        },
        {
          hover: false,
          img: 'https://img.kcgeis.com/8237578829.png',
          hoverImg: 'https://img.kcgeis.com/0239277774.png',
          title: '咨询热线',
          detail: '',
        },
        {
          hover: false,
          img: 'https://img.kcgeis.com/3335677513.png',
          hoverImg: 'https://img.kcgeis.com/5328502828.png',
          title: '网址',
          detail: '',
        },
        {
          hover: false,
          img: 'https://img.kcgeis.com/0681666503.png',
          hoverImg: 'https://img.kcgeis.com/6139409261.png',
          title: '公司地址',
          detail: '',
        },
      ],
    };
  },
  async created() {
    await this.contactInfo();
    await this.getClass();
  },

  methods: {
    mouseenter(index) {
      this.contactList[index].hover = true;
    },
    mouseleave(index) {
      this.contactList[index].hover = false;
    },
    submitForm() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          try {
            const params = {
              content: this.form.content,
              name: this.form.name,
              phone: this.form.phone,
              leaveClassId: this.form.classid,
            };
            await leave(params);
            this.$message({
              type: 'success',
              message: '提交成功',
              offset: 100,
            });
          } catch (error) {
            this.$showError(error);
          }
          this.resetForm();
        }
      });
    },
    resetForm() {
      this.$refs.form.resetFields();
    },
    async getClass() {
      try {
        const classData = await leaveClass();
        this.classList = classData.data;
      } catch (error) {
        this.$message.error(error);
      }
    },
    async contactInfo() {
      try {
        const { data } = await contactInfo();
        this.contactList[0].detail = data[0].content;
        this.contactList[1].detail = data[0].phone;
        this.contactList[2].detail = 'http://www.gdayy.com';
        this.contactList[3].detail = data[0].site;
      } catch (error) {
        this.$message.error(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  position: relative;
  .map {
    width: 100%;
    height: 450px;
    .bm-view {
      width: 100%;
      height: 100%;
    }
  }
  .content {
    position: relative;
    width: 100%;
 
    .title {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 1200px;
      height: 50px;
      margin: 50px auto;
      span {
        font-size: 14px;
        color: #434343;
      }
    }
    .contact-info {
      width: 1200px;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      .contactList {
        position: relative;
        width: 300px;
        height: 200px;
        margin-bottom: 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 90px 25px 0;
        &:nth-child(n + 2) {
          border-left: 1px solid #ccc;   
        }
        
        .item-icon {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          height: 90px;
          background-image: var(--src);
          background-repeat: no-repeat;
          background-size: auto;
          background-position: center;
          transition: all 0.3s ease-out;

          &:hover {
            background-image: var(--hover-src);
          }
        }
        .item-title {
          color: #1d2122;
          font-weight: 800;
          font-size: 18px;
          margin-top: 10px;
        }
        .item-detail {
          margin-bottom: 10px;
          text-align: center;
        }
      }
    }
  }
  .call {
    width: 100%;
    background-color: #eee;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .title {
      padding: 30px;
      h3 {
        font-size: 24px;
        color: #434343;
        margin-bottom: 10px;
      }
    }
    .card {
      background: #fff;
      width: 1000px;
      height: 500px;
      padding: 50px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 50px;
      span {
        width: 100%;
        font-size: 14px;
        font-weight: 300;
        color: #018835;
      }
      .el-form {
        margin-top: 20px;
        width: 900px;
      }
    }
  }

  // 修改type为success的 按钮颜色
  .el-button--success:focus,
  .el-button--success:hover {
    background: #fff;
    border-color: #018835;
    color: #018835;
  }
  .el-button--success {
    color: #fff;
    background-color: #018835;
    border-color: #fff;
  }
  // 修改type为primary的 按钮颜色
  .el-button--primary:focus,
  .el-button--primary:hover {
    color: #fff;
    background-color: #018835;
    border-color: #fff;
  }
  .el-button--primary {
    background: #fff;
    border-color: #018835;
    color: #018835;
  }

  ::v-deep.el-textarea__inner:focus {
    border-color: #018835;
  }
  ::v-deep.el-input__inner:focus {
    border-color: #018835;
  }
  ::v-deep.el-input__inner {
    width: 200px;
  }
}
</style>
