<template>
  <!-- 联系我们 -->
  <div class="main">
    <div class="content">
      <!-- 标题 -->
      <div class="Box">
        <span class="Title">JOIN US</span>
        <span class="Chtitle">招聘信息</span>
      </div>
      <div class="box">
        <p>
          {{ joinList.name }}
        </p>
        <div class="require">
          <div class="left">
            <img :src="joinList.imgUrl" alt="">
          </div>
          <div class="right">
            <span class="top" v-html="joinList.details" />
            <RouterLink :to="{ path: '/Joinin' }">
              <span class="button">了解详情</span>
            </RouterLink>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { join } from '@/api/about';

export default {
  name: 'JoinIndex',
  data() {
    return {
      joinList: {},
    };
  },
  async created() {
    await this.join();
  },
  methods: {
    async join() {
      try {
        const params = {
          id: this.id,
        };
        const res = await join(params);
        this.joinList = res.data[0];
      } catch (error) {
        this.$message.error(error);
      }
    },
   
  },
};
</script>

<style lang="scss" scoped>

.main {
  width: 100%;
  position: relative;
  .content {
    position: relative;
    width: 100%;
  

    .box {
      width: 1200px;
      margin: 50px auto 150px;
      p {
        font-size: 18px;
        font-weight: bold;
        color: #000;
        margin-bottom: 10px;
      }
      .require {
        width: 1200px;
        height: 400px;
        display: flex;
        justify-content: space-between;
        .left {
          width: 660px;
          height: 400px;
          background-color: #eee;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .right {
          width: 500px;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
          .top {
            height: 345px;
            overflow: hidden;
          }
          .button {
            width: 114px;
            line-height: 40px;
            text-align: center;
            display: block;
            color: #fff;
            background: #018835;
            border: #eee 1px solid;
            border-radius: 5px;
            font-size: 15px;
            transition: all 0.3s;
            &:hover {
              color: #018835;
              background: #fff;
              transition: all 0.3s;
              border: #eee 1px solid;
            }
          }
        }
      }
    }
  }
}
</style>
