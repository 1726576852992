var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main"},[_c('div',{staticClass:"map"},[_c('baidu-map',{staticStyle:{"height":"100%"},attrs:{"center":_vm.center,"zoom":_vm.zoom,"scroll-wheel-zoom":true}},[_c('bm-geolocation',{attrs:{"anchor":"BMAP_ANCHOR_BOTTOM_RIGHT","show-address-bar":true,"auto-location":true}}),_c('bm-marker',{attrs:{"position":{ lng: 113.13816, lat: 22.56931 },"animation":"BMAP_ANIMATION_BOUNCE"}},[_c('bm-label',{attrs:{"content":"广东安雅园新型材料科技有限公司","offset":{ width: -55, height: 30 }}})],1)],1)],1),_c('div',{staticClass:"content"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"contact-info"},[_vm._l((_vm.contactList),function(item,index){return [_c('div',{key:index,staticClass:"contactList"},[_c('div',{staticClass:"item-icon",style:({
              '--src': `url(${ item.img })`, 
              '--hover-src': `url(${ item.hoverImg })`
            })}),_c('div',{staticClass:"item-title"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('div',{staticClass:"item-detail"},[_vm._v(" "+_vm._s(item.detail)+" ")])])]})],2)]),_c('div',{staticClass:"call",attrs:{"id":"contact0"}},[_vm._m(2),_c('div',{staticClass:"card"},[_c('span',[_vm._v("温馨提示：有任何问题或需求，欢迎您随时咨询我们，也可以填写下面留言板，我们会即时为您服务！")]),_c('ElForm',{ref:"form",attrs:{"model":_vm.form,"rules":_vm.rules,"label-width":"auto","hide-required-asterisk":""}},[_c('ElFormItem',{attrs:{"label":"姓名","prop":"name"}},[_c('ElInput',{model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('ElFormItem',{attrs:{"label":"电话","prop":"phone"}},[_c('ElInput',{model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}})],1),_c('ElFormItem',{attrs:{"label":"选择资讯范围","prop":"classid"}},[_c('ElSelect',{staticStyle:{"width":"100%"},attrs:{"placeholder":"请选择资讯的内容"},model:{value:(_vm.form.classid),callback:function ($$v) {_vm.$set(_vm.form, "classid", $$v)},expression:"form.classid"}},_vm._l((_vm.classList),function(item){return _c('ElOption',{key:item.value,attrs:{"label":item.name,"value":item.id}})}),1)],1),_c('ElFormItem',{attrs:{"label":"详细信息","prop":"content"}},[_c('ElInput',{attrs:{"type":"textarea","rows":5},model:{value:(_vm.form.content),callback:function ($$v) {_vm.$set(_vm.form, "content", $$v)},expression:"form.content"}})],1),_c('ElFormItem',[_c('ElButton',{attrs:{"type":"success"},on:{"click":_vm.submitForm}},[_vm._v(" 提交 ")]),_c('ElButton',{attrs:{"type":"primary"},on:{"click":_vm.resetForm}},[_vm._v(" 重置 ")])],1)],1)],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"Box"},[_c('span',{staticClass:"Title"},[_vm._v("CONTACT US")]),_c('span',{staticClass:"Chtitle"},[_vm._v("联系我们")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('span',[_vm._v("如果您正在考虑选择一个轻钢材料供应商？那么，最好的厂家就在这里。 ")]),_c('span',[_vm._v("在安雅园建筑新型材料，我们致力于成为轻钢行业最值得信赖的综合服务商，始终秉承以技术为核心，以品质为保障，以服务为宗旨，为客户提供出最优的解决方案，真正的做到服务客户，尊重客户。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('h3',[_vm._v(" 在线留言 ")])])
}]

export { render, staticRenderFns }